<template>
  <div class="container-fluid card-body">
    <Load :isLoading="isLoading" />
    <!-- MODAL CONFIRMACAO -->
    <div class="modal-mask animated" v-if="modalFalta">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Confirmar falta</h4>
            <button type="button" class="close" data-dismiss="modal" @click="modalFalta = false">&times;</button>
          </div>
          <div class="modal-body">
            <div class="row px-3">
              <div class="col-12 mb-3">
                  <h5>
                    Deseja confirmar falta para as pessoas e datas abaixo?
                  </h5>
                </div>
              <div class="col-12">
                <table class="table table-striped table-hover table-bordered">
                  <tbody>
                    <tr v-for="(item, index) in faltas" :key="index">
                      <td scope="row">{{item.funcionario_nome}}</td>
                      <td>
                        <div v-for="(item, index) in item.datas" :key="index">
                          {{item}}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="gerarFaltaConfirmar()">Sim, tenho certeza.</button>
            <button type="button" class="btn btn-danger" @click="modalFalta = false">Não, cancelar.</button>
          </div>
        </div>
      </div>
    </div>
    <!-- END MODAL -->
    <div class="row">
      <div class="col">
        <h1>Registrar faltas do treinamento</h1>
        <h6>Clique no funcionário e data que deseja registrar a falta</h6>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <button type="button" class="btn btn-primary btn-dark-color btn-hover mb-3" @click="gerarFalta()">
          Gerar faltas
        </button>
        <table class="table table-striped table-hover table-bordered">
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
              <th>Empresa</th>
              <th v-for="(data, index) in datas" :key="index">{{data}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in funcionarios" :key="index">
              <!--
              <td scope="row">
                <Checkbox @checkOption="checkItem" :valueProp="index" :checkProp="isCheckedAll(item.funcionario_id)"></Checkbox>
              </td>
              -->
              <td>{{item.funcionario.nome}}</td>
              <td>{{item.funcionario.cpf}}</td>
              <td>{{item.empresa.nome_fantasia}}</td>
              <th v-for="(data, index) in datas" :key="index">
                <Checkbox @checkOption="checkItem" :valueProp="{data: data, item: item}" v-if="possuiFalta(item.funcionario_id, data)"></Checkbox>
                <span v-else> Já possui falta</span>
              </th>
            </tr>
          </tbody>
        </table>
        <button type="button" class="btn btn-primary btn-dark-color btn-hover my-3" @click="gerarFalta()">
          Gerar faltas
        </button>
      </div>
    </div>
    <hr>
    <!--
    <div class="row">
      <div class="col">
        <button type="button" class="btn btn-primary btn-dark-color btn-hover" @click="gerarCertificados">
          Gerar certificados
        </button>
      </div>
    </div>
    -->
  </div>
</template>

<script>
import treinamentoRepository from './../../services/api/treinamentoRepository'
import Checkbox from './../../uicomponents/Checkbox'
import moment from "moment"
export default {
  components: {
    Checkbox
  },
  created () {
    console.log(this.modalFalta)
    if (this.$route.query.treinamento) {
      this.fetch(this.$route.query.treinamento)
    }
  },
  data () {
    return {
      isLoading: false,
      funcionarios: [],
      treinamento: null,
      faltas: [],
      faltasExistentes: [],
      modalFalta: false
    }
  },
  computed: {
    datas() {
      if(this.treinamento == null)
      {
        return []
      } else {
        let periodo = []
        var date1 = moment(this.treinamento.data_inicio)
        var date2 = moment(this.treinamento.data_fim)
        while(date1 <=  date2) {
          periodo.push(date1.format('DD/MM/YYYY'))
          date1 = date1.add(1, 'day')
        }
        return periodo
      }
    },
  },
  methods: {
    possuiFalta(funcionario_id, data) {
      funcionario_id = parseInt(funcionario_id)
      data = data.split('/').reverse().join('-')
      return !this.faltasExistentes.some( fe => fe.funcionario_id == funcionario_id && fe.data_falta == data)
    },
    async fetch (idTreinamento) {
      this.isLoading = true
      treinamentoRepository.buscarFaltasTreinamento(idTreinamento).then(response => {
        if(response.data['success']) {
          this.faltasExistentes = response.data['data']
        }
      })
      let responseTreinamento = await treinamentoRepository.buscaTreinamentoPorId(idTreinamento)
      this.isLoading = false
      if(responseTreinamento.data['success']) {
        this.treinamento = responseTreinamento.data['data']
        await this.buscaFuncinarios(idTreinamento)
      }      
    },
    async buscaFuncinarios(idTreinamento) {
      this.isLoading = true
      let response = await treinamentoRepository.buscaTreinamentoFuncionariosPorTreinamentoId(idTreinamento)
      this.isLoading = false
      if(response.data['success']) {
        this.funcionarios = response.data['data']
      }
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push({ name: 'Funcionarios' })
    },
    checkItem(isChecked, item) {
      if(isChecked) {
        let pessoaIndex = this.faltas.findIndex(f => f.funcionario_id == item.item.funcionario_id)
        // SE A PESSOA JA ESTIVER NA LISTA, ADICIONA SOMENTE A DATA
        if(pessoaIndex >= 0) {
          this.faltas[pessoaIndex].datas.push(item.data)
          this.faltas[pessoaIndex].datas.sort(function(a,b){
            return new Date(a.split('/').reverse().join('-')) - new Date(b.split('/').reverse().join('-'))
          })
        } else {
          // SE ELA NAO EXISTIR NA LISTA, CRIA O REGISTRO E ADICIONA A DATA
          this.faltas.push({datas: [item.data], funcionario_id: item.item.funcionario_id, treinamento_id: item.item.treinamento_id, funcionario_nome: item.item.funcionario.nome})
        }
      } else {
        // ACHA O INDICE DA PESSOA NA LISTA
        let index = this.faltas.findIndex(f => f.funcionario_id == item.item.funcionario_id && f.treinamento_id == item.item.treinamento_id);
        // ACHA O INDICE DA DATA QUE ESTA DESMARCANDO
        let dataIndex = this.faltas[index].datas.findIndex(d => d == item.data)
        // REMOVE A DATA DO ARRAY DE DATA DE FALTA
        this.faltas[index].datas.splice(dataIndex, 1)
        // SE NAO POSSUIR MAIS NENHUMA DATA REMOVE A PESSOA DA LISTA
        if(this.faltas[index].datas.length == 0) {
          this.faltas.splice(index, 1);
        }
      }
      console.log(this.faltas)
    },
    gerarFalta() {
      console.log(this.faltas)
      if(this.faltas.length == 0)
      {
        this.$swal({
          icon: 'warning',
          text: 'Selecione pelo menos uma pessoa e uma data para gerar falta!'
        })
      } else {
        this.modalFalta = true
      }
    },
    gerarFaltaConfirmar() {
      this.isLoading = true
      treinamentoRepository.gerarFaltaTreinamento(this.faltas).then((response) => {
        this.modalFalta = false
        this.isLoading = false
        if(response.data['success']) {
          this.$swal({
            icon: 'success',
            text: 'Faltas registradas com sucesso!'
          }).then(() => {
            this.goBack()
          })
        }
      }).catch(() => {
        this.modalFalta = false
        this.isLoading = false
        return false
      })
    }
  }
}
</script>

<style>

</style>