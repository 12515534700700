<template>
  <div>
    <div class="chiller_cb d-flex">
      <input id="myCheckbox" type="checkbox" :checked="check">
      <span @click="checkItem()"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'valueProp', 'checkProp'
  ],
  created () {
    //this.check = this.checkProp
  },
  watch: {
    checkProp: function () {
      this.check = this.checkProp
    }
  },
  data () {
    return {
      check: false
    }
  },
  methods: {
    checkItem () {
      this.check = !this.check
      this.$emit('checkOption', this.check, this.valueProp)
    }
  }
}
</script>

<style scoped>
.span_pseudo, .chiller_cb span:before, .chiller_cb span:after {
  content: "";
  display: inline-block;
  background: #fff;
  width: 0;
  height: 0.2rem;
  position: absolute;
  transform-origin: 0% 0%;
}
.chiller_cb {
  position: relative;
  /*height: 2rem;*/
  /*display: flex;*/
  align-items: center;
}
.chiller_cb input {
  display: none;
}
.chiller_cb input:checked ~ span {
  background: #066278;
  border-color: #066278;
}
.chiller_cb input:checked ~ span:before {
  width: 1rem;
  height: 0.15rem;
  transition: width 0.1s;
  transition-delay: 0.3s;
}
.chiller_cb input:checked ~ span:after {
  width: 0.4rem;
  height: 0.15rem;
  transition: width 0.1s;
  transition-delay: 0.2s;
}
.chiller_cb input:disabled ~ span {
  background: #ececec;
  border-color: #dcdcdc;
}
.chiller_cb input:disabled ~ label {
  color: #dcdcdc;
}
.chiller_cb input:disabled ~ label:hover {
  cursor: default;
}
.chiller_cb label {
  padding-left: 2rem;
  position: relative;
  z-index: 2;
  cursor: pointer;
  margin-bottom:0;
}
.chiller_cb span {
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  border: 2px solid #ccc;
  position: absolute;
  left: 0;
  transition: all 0.2s;
  z-index: 1;
  box-sizing: content-box;
}
.chiller_cb span:before {
  transform: rotate(-55deg);
  top: 1rem;
  left: 0.37rem;
}
.chiller_cb span:after {
  transform: rotate(35deg);
  bottom: 0.35rem;
  left: 0.2rem;
}
</style>